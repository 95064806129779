import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { BreadCrumbItem } from './core/models/bread-crumb-item.model';
import { AuthService } from './core/services/auth.service';
import { LoadingService } from './core/services/loading.service';
import { MenuService } from './core/services/menu.service';
import * as moment from 'moment';
import { SignalRService } from './core/services/signal-r.servirce';
import { HubConnectionState } from '@microsoft/signalr';
import { DateAdapter } from '@angular/material/core';
import { ContainerScrollingService } from './core/services/container-scrolling.service';
import { PerfilPermissaoService } from './core/services/perfil-permissao.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { NavigationEnd, Router, Event } from '@angular/router';

import { PoolingService } from './services/pooling/pooling.service';
import { MessageService } from './core/services';
import { FirebaseService } from './services/firebase.service';
import { ApimConfig, ApimService } from './core/services/apim.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  isLogisticScreen: boolean = false;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  perfilPrimariaRecebeAnomalia = ["MonitorPrimaria", "AnalistaVD", "AnalistaProjetos", "AdminPrimaria"];
  perfilDistribuicaoRecebeAnomalia = ["RiscoDistribuicao", "SegurancaDistribuicao", "MonitorDistribuicao", "GestorDistribuicao", "TransporteDistribuicao", "AdminDistribuicao"];

  loading: boolean = false;

  isLoginScreen: boolean;
  isLoginScreenCompleted: boolean;

  title = 'web';

  // TODO: Paliativo até migrarmos tudo por página
  private urlLink: string;

  private breadCrumb: BreadCrumbItem[];
  private backGroundColor: string;

  static turing: string;

  @ViewChild("snav") snav: MatSidenav;

  private _toggle: boolean;
  public get toggle(): boolean {
    return this._toggle;
  }

  public set toggle(value: boolean) {
    this._toggle = value;
    setTimeout(() => {
      if (this._toggle && this.snav)
        this.snav.open();
      else if (this.snav)
        this.snav.close();
    }, 200);
  }
  readonly myVar = 1;

  constructor(
    private signalRService: SignalRService,
    private loadingService: LoadingService,
    private menuService: MenuService,
    private authService: AuthService,
    private containerScrollingService: ContainerScrollingService,
    private changeDetectorRef: ChangeDetectorRef, private media: MediaMatcher, private translate: TranslateService,
    private perfilPermissaoService: PerfilPermissaoService,
    private dateAdapter: DateAdapter<any>,
    private localStorage: LocalStorageService,
    private messageService: MessageService,
    private router: Router,
    private firebaseService: FirebaseService,
    private apimService: ApimService,
    _: PoolingService
  ) {
    translate.setDefaultLang('pt');
    moment.locale('pt');
    dateAdapter.setLocale('pt');
  }


  public ngOnInit(): void {
    // Initialize Firebase
    this.firebaseService.init();
    this.apimService.initConfig();
    this.router.events.subscribe((event: Event) => {
      if (
        event instanceof NavigationEnd &&
        event.url.split('?')[0] !== '/' &&
        event.url.split('?')[0] !== '/login' &&
        event.url.split('?')[0] !== '/forgot-password' &&
        event.url.split('?')[0] !== '/new-password'
      ) {
        const id = setInterval(() => {
          this.authService.userData.subscribe((user) => {
            if (user == null) {
              this.router.navigate(['/login']);
              clearInterval(id);
            }
          });
        }, 2000);
      }

      if (event instanceof NavigationEnd && event.url === '/logistic') {
        if (!this.isLogisticScreen) {
          this.isLogisticScreen = true;
        }
      }
    });

    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.menuService.onChangeBackGroundColor.subscribe(backGroundColor => {
      this.backGroundColor = backGroundColor;
    });

    this.menuService.onChangeBreadCrumb.subscribe(breadCrumb => {
      this.breadCrumb = breadCrumb;
    });

    this.loadingService.onLoading.subscribe(loading => {
      this.loading = loading;
    });

    this.isLoginScreenCompleted = false;
    this.authService.onLoginScreen.subscribe(isLoginScreen => {
      this.isLoginScreen = isLoginScreen;
      setTimeout(() => {
        this.isLoginScreenCompleted = true;
      }, 200);
    });

    setTimeout(() => {
      this.toggle = this.mobileQuery.matches ? false : true;
    }, 200);

    this.authService.userData.subscribe((user) => {
      if (user != null) {
        var group = this.verificaRecebimentoAnomalias();
        if (this.signalRService.connectionState != HubConnectionState.Connected) {
          const result: ApimConfig = this.apimService.config;
          this.signalRService.startConnection(result).then(() => {

            //this.signalRService.WaitingGroups(); // TODO: Não está sendo usado em lugar algum
            this.signalRService.WaitingPoints();
            this.signalRService.WaitingCalc();
            this.signalRService.WaitingRelatorios();
            if (group !== null) {
              this.signalRService.WaitingAnomalies();
              this.signalRService.AddToGroups(`anomalia${group.logistica.toLowerCase()}`);
            }

            let addGroupPlates = this.localStorage.getItem<string[]>('AddGroupsPlates');

            if (addGroupPlates) {
              // console.log("[ADD GROUP PLATES]", addGroupPlates);
              this.signalRService.AddToGroups(addGroupPlates.join());
            }
            this.signalRService.AddToGroups(`Relatorios${user.preferred_username}`);

          })
            .catch(err => console.log('Error while starting connection: ' + err));
        }
      }
    });
  }

  verificaRecebimentoAnomalias() {
    var currentLogisticaRole = this.perfilPermissaoService.getCurrentLogisticaRole();

    if (currentLogisticaRole.logistica === 'agro' || currentLogisticaRole.logistica === 'commodities') {
      return currentLogisticaRole;
    }

    if (currentLogisticaRole !== null) {
      if (this.perfilPrimariaRecebeAnomalia.includes(currentLogisticaRole.codigo)) {
        return currentLogisticaRole;
      }
      if (this.perfilDistribuicaoRecebeAnomalia.includes(currentLogisticaRole.codigo)) {
        return currentLogisticaRole;
      }
    }
    return null;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  @HostListener('scroll', ['$event'])
  onElementScroll($event) {
    this.containerScrollingService.emitEvent($event);
  }

  checkUrlLogin(): boolean {
    let url = window.location.href;
    if (url[url.length - 1] == '/') {
      url = url.substr(0, url.length - 1);
    }
    var result = (url.indexOf('login') != -1 || url ==
      window.location.origin);

    return result;
  }

  checkUrlPrintPreview() {
    let url = window.location.href;
    return url.indexOf('print-preview') != -1;
  }

  setBreadCrumb(list: BreadCrumbItem[]) {
    this.breadCrumb = list;
    if (this.mobileQuery.matches) {
      this._toggle = false;
      this.snav.close();
    }
  }
}
