import { inject, Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { HubConnectionState } from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { MessageService } from '.';
import { Anomaly } from '../models';
import { ReportInformation } from "../../pages/reports/report.model";
import { PerfilPermissaoService } from './perfil-permissao.service';
import { LogisticTypeEnum } from '../models/enums/logistic-type.enum';
import { environment } from 'src/environments/environment';
import { ApimConfig } from './apim.service';

class CustomHttpClient extends signalR.DefaultHttpClient {

  constructor(
    private config: ApimConfig
  ) {
    super(null);
  }
  public send(request: signalR.HttpRequest,): Promise<signalR.HttpResponse> {
    const currentRole = JSON.parse(localStorage.getItem('CURRENT_ROLE'));
    const config: ApimConfig = this.config;

    if (currentRole.logistica === LogisticTypeEnum.PRIMARY) {
      request.headers = { ...request.headers, [config.label_authorization_key]: config.subscription_hub_primary };
      return super.send(request);
    }
    if (currentRole.logistica === LogisticTypeEnum.DISTRIBUITION) {
      request.headers = { ...request.headers, [config.label_authorization_key]: config.subscription_hub_distribution };
      return super.send(request);
    }
    if ((currentRole.logistica === LogisticTypeEnum.AGRONOMY || currentRole.logistica === LogisticTypeEnum.COMMODITIES)) {
      request.headers = { ...request.headers, [config.label_authorization_key]: config.subscription_hub_agro_commodities };
      return super.send(request);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private anomalyData: Subject<{ anomaly: Anomaly, action: string }>;

  private pointData: Subject<{ point: any, action: string }>;
  private calcData: Subject<{ calc: any, action: string }>;
  private relatorioGerado: Subject<{ id: string, action: string }>;
  private hubConnection: signalR.HubConnection;

  constructor(private messageService: MessageService, private perfilPermissaoService: PerfilPermissaoService) {
    this.anomalyData = new Subject();
    this.pointData = new Subject();
    this.calcData = new Subject();
    this.relatorioGerado = new Subject();
  }

  public get connectionState(): HubConnectionState {
    if (this.hubConnection)
      return this.hubConnection.state;
    else
      return HubConnectionState.Disconnected;
  }

  public startConnection = async (config: ApimConfig) => {
    let hub_url = environment.base_signal_r_hub;
    if (this.perfilPermissaoService.getCurrentLogisticaRole().logistica == LogisticTypeEnum.PRIMARY) {
      hub_url = environment.base_signal_r_hub_primary;
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(hub_url, { httpClient: new CustomHttpClient(config) })
        .withAutomaticReconnect()
        .build();
      return this.hubConnection
        .start();
    }
    if (this.perfilPermissaoService.getCurrentLogisticaRole().logistica == LogisticTypeEnum.DISTRIBUITION &&
      environment.base_signal_r_hub_distribution.length > 0) {
      hub_url = environment.base_signal_r_hub_distribution;
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(hub_url, { httpClient: new CustomHttpClient(config) })
        .withAutomaticReconnect()
        .build();
      return this.hubConnection
        .start();
    }
    if ((this.perfilPermissaoService.getCurrentLogisticaRole().logistica === LogisticTypeEnum.AGRONOMY ||
      this.perfilPermissaoService.getCurrentLogisticaRole().logistica === LogisticTypeEnum.COMMODITIES) &&
      environment.base_signal_r_hub_agro_commodities.length > 0) {
      hub_url = environment.base_signal_r_hub_agro_commodities;
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(hub_url, { httpClient: new CustomHttpClient(config) })
        .withAutomaticReconnect()
        .build();

      return this.hubConnection
        .start();
    }
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hub_url, { httpClient: new CustomHttpClient(config) })
      .withAutomaticReconnect()
      .build();
    return this.hubConnection
      .start();
  }

  public AddToGroup(groupName: string): void {
    this.hubConnection.send('AddToGroup', groupName).then(result => {
    });
  }
  public AddToGroups(groupNames: string): void {
    this.hubConnection.send('AddToGroups', groupNames).then(result => {
    });
  }
  public SendToGroup(groupName: string, msg: string): void {
    this.hubConnection.send('SendToGroup', groupName, msg).then(result => {
    });
  }

  public RemoveFromGroup(groupName: string): void {
    this.hubConnection.send('LeaveGroup', groupName).then(result => {
      console.log(result);
    });
  }
  public RemoveFromGroups(groupNames: string): void {

    // console.log("Removido do brupo" +  groupNames)
    this.hubConnection.send('LeaveGroups', groupNames).then(result => {
      console.log(result);
    });
  }

  public WaitingAnomalies(): void {
    this.hubConnection.on('AnomaliaReceived', (data, action) => {
      if (data) {
        console.log(data)
        // TODO: Estamos desabilitando temporariamente as notificações até que seja alinhado uma nova solução

        // const currentLogisticaRole = this.perfilPermissaoService.getCurrentLogisticaRole();

        // const urlDetailAnomaly = currentLogisticaRole.logistica === 'agro' || currentLogisticaRole.logistica === 'commodities'
        //   ? `load/${data.rota.numeroViagem}/anomaly/${data.id}`
        //   : `anomaly/${data.id}`;

        // const buttonAction = { nome: 'Tratar agora', navigateUrl: urlDetailAnomaly };

        // if (action === 'standby') {
        //   this.messageService.success(`Anomalia "<b>${data.nome}</b>" da viagem ${data.rota.numeroViagem} está de volta na sua fila`, 'outline/white/time.svg', 15000, buttonAction, true);
        // } else if (action === 'new') {
        //   this.messageService.success(`Nova anomalia "<b>${data.nome}</b>" gerada na viagem ${data.rota.numeroViagem} foi adicionada na sua fila`, 'outline/white/time.svg', 15000, buttonAction, true);
        // }
      }
      this.setAnomaly(data, action);
    });
  }

  public WaitingGroups(): void {
    this.hubConnection.on('GroupReceived', (data) => {
    });
  }

  public get onAnomalyReceived(): Observable<{ anomaly: Anomaly, action: string }> {
    return this.anomalyData.asObservable();
  }

  public setAnomaly(anomaly: any, action: string) {
    this.anomalyData.next({ anomaly: anomaly, action: action });
  }

  public get onPointReceived(): Observable<{ point: any, action: string }> {
    return this.pointData.asObservable();
  }


  public get onCalcReceived(): Observable<{ calc: any, action: string }> {
    return this.calcData.asObservable();
  }

  public get onRelatorioReceived(): Observable<{ id: string, action: string }> {
    return this.relatorioGerado.asObservable();
  }

  public setPoint(point: any, action: string) {
    this.pointData.next({ point: point, action: action });
  }

  public setCalc(calc: any, action: string) {
    this.calcData.next({ calc: calc, action: action });
  }

  public setRelatorio(id: any, action: string) {
    this.relatorioGerado.next({ id: id, action: action });
  }

  public WaitingPoints(): void {
    this.hubConnection.on('MonitoramentoParadas', (data, action) => {
      if (data) {
        this.setPoint(data, action);
      }
    });
  }

  public WaitingCalc(): void {
    this.hubConnection.on('MonitoramentoCalculoRota', (data, action) => {
      if (data) {
        this.setCalc(data, action);
      }
    });
  }

  public WaitingRelatorios(): void {
    this.hubConnection.on('RelatorioGerado', (data, action) => {
      if (data) {
        if (window.location.href.match('/reports')) {
          this.setRelatorio(data, action);
        } else {

          const reportName = Object.keys(ReportInformation).find(key => ReportInformation[key].type === data?.tipoRelatorio);

          if (action === 'success') {
            const buttonAction = { nome: 'Visualizar', navigateUrl: `reports/${ReportInformation[reportName].url}` };
            this.messageService.success(
              `Relatório de ${ReportInformation[reportName].label} gerado com sucesso!`,
              'outline/white/time.svg',
              15000, buttonAction,
              true);
          } else {
            const buttonAction = { nome: 'Ir para Relatório', navigateUrl: `reports/${ReportInformation[reportName].url}` };
            this.messageService.error(`Nenhum dado encontrado para o relatório de ${ReportInformation[reportName].label}. Refaça seus filtros.`,
              null, 15000, buttonAction);
          }
        }
      }
    });
  }
}
