
export const environment = {
  production: true,

  base_api: "http://brf-api-onelog-d-service.onelog.svc.cluster.local/api",
  base_signal_r_hub: "https://apim-qas.brf.cloud/hub-d/api",

  base_api_primary: "https://ygg-qas.brf.cloud/onelog/primaria/web/api",
  base_signal_r_hub_primary: "https://apim-qas.brf.cloud/hub-p/api",
  base_api_distribution: "https://ygg-qas.brf.cloud/onelog/distribuicao/web/api",
  base_api_distribution_diaria: "https://ygg-qas.brf.cloud/onelog/govdiarias/web/api",
  base_signal_r_hub_distribution: "https://apim-qas.brf.cloud/hub-d/api",
  base_api_agro_commodities: "",
  base_signal_r_hub_agro_commodities: "",

  login_transporter_distribution: "https://ygg-qas.brf.cloud/onelog/distribuicao/transportadora/login",
  login_transporter_primary: "https://ygg-qas.brf.cloud/onelog/primaria/transportadora/login",

  express_url: "https://ygg-qas.brf.cloud/onelog/server"
};
